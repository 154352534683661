/*
Name: 			theme-shop.css
Written by: 	Okler Themes - (http://www.okler.net)
Theme Version:	7.6.0
*/
/* Custom Font - Stars (From WooCommerce) */
/*@font-face {*/
/*	font-family: 'star';*/
/*	src: url("fonts/star.eot");*/
/*	src: url("fonts/star.eot?#iefix") format("embedded-opentype"), url("fonts/star.woff") format("woff"), url("fonts/star.ttf") format("truetype"), url("fonts/star.svg#star") format("svg");*/
/*	font-weight: normal;*/
/*	font-style: normal;*/
/*}*/
/* Shop - Base */
.shop .products:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.shop .products .product {
	position: relative;
	margin-bottom: 30px;
}

.shop .products .product a {
	text-decoration: none;
}

.shop .products .product .add-to-cart-product {
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	position: absolute;
	top: -100%;
	left: 0;
	padding: 8px 10px 5px;
	font-size: 0.9em;
	color: #FFF;
	background: #222;
	z-index: 1;
	opacity: 0;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.shop .products .product .add-to-cart-product [class*="fa-"], .shop .products .product .add-to-cart-product .icons {
	margin-right: 3px;
	font-size: 1.1em;
}

.shop .products .product:hover .add-to-cart-product {
	opacity: 1;
	top: 0;
}

.shop .products .product:hover .add-to-cart-product:hover {
	background: #000;
}

.shop .products .product .price {
	display: block;
	min-height: 28px;
}

.shop .products .product .price del {
	color: rgba(145, 145, 145, 0.5);
	font-size: 0.7em;
	margin: -2px 0 0;
}

.shop .products .product .price ins {
	text-decoration: none;
}

.shop .products .product .price .amount {
	font-size: 1.3em;
}

.shop .products .product .onsale {
	position: absolute;
	background-image: none;
	border-radius: 100%;
	left: auto;
	right: 0;
	top: -15px;
	text-shadow: none;
	width: 40px;
	height: 40px;
	line-height: 40px;
	display: inline-block;
	text-align: center;
	z-index: 10;
	-webkit-box-shadow: none;
	box-shadow: none;
	font-size: 0.9em;
	color: #FFF;
	border-bottom: 2px solid transparent;
}

.masonry-loader-showing .products {
	opacity: 0;
}

/* Product Info */
.product-info {
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	padding: 0;
	position: relative;
	border: 1px solid #DDD;
	background: #F7F9FC;
	display: block;
	overflow: hidden;
	position: relative;
	text-decoration: none;
	max-width: 100%;
	text-align: center;
	padding: 0;
	text-decoration: none;
}

.product-info:hover {
	background-color: #FFF;
	text-decoration: none;
}

.product-info a {
	text-decoration: none;
	display: inline-block;
	width: 100%;
}

.product-info a:hover {
	text-decoration: none;
	display: inline-block;
	width: 100%;
}

.product-info img {
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}

.product-info h4 {
	color: #404751;
	letter-spacing: -1px;
	font-weight: 600;
	font-size: 1.4em;
	line-height: 1.1em;
	margin: 0;
	padding: 0;
}

.product-info + .product-info {
	margin-top: -5px;
}

.product-info .product-info-image {
	display: block;
	position: relative;
}

.product-info .product-info-image:before {
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #333;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.product-info:hover .product-info-image:before {
	opacity: 0.4;
}

.product-info .product-info-act {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	text-align: center;
	color: #FFF;
}

.product-info .product-info-act em {
	font-style: normal;
	position: relative;
	font-size: 1.2em;
	font-weight: 100;
}

.product-info .product-info-act-left, .product-info .product-info-act-right {
	float: left;
	overflow: hidden;
}

.product-info .product-info-act-left {
	text-align: right;
	width: 45%;
}

.product-info .product-info-act-left em {
	-webkit-transition: all 0.2s ease 0s;
	transition: all 0.2s ease 0s;
	left: 0%;
	opacity: 0;
}

.product-info .product-info-act-right {
	text-align: left;
	margin-left: 2%;
	width: 53%;
}

.product-info .product-info-act-right em {
	-webkit-transition: all 0.2s ease 0s;
	transition: all 0.2s ease 0s;
	right: 0%;
	font-weight: 600;
	opacity: 0;
}

.product-info:hover .product-info-act-left em {
	opacity: 1;
}

.product-info:hover .product-info-act-right em {
	opacity: 1;
}

.product-info .product-info-content {
	padding: 15px;
	text-align: left;
	display: block;
}

.product-info .product-info-caption {
	padding: 0 15px 15px;
	text-align: left;
	display: block;
}

.product-info .product-info-caption p {
	margin: 0;
	padding: 0 0 10px;
}

.product-info .product-info-extra {
	clear: both;
	padding: 10px 0 0;
	margin: 10px 0 0;
	border-top: 1px solid #EBEBF4;
	display: block;
}

.product-info .product-info-extra.social {
	text-align: center;
}

.product-info .product-info-extra.social a {
	border-right: 1px solid #EBEBF4;
	display: inline-block;
	margin-right: 10px;
	padding-right: 10px;
	font-size: 0.9em;
	color: #ACACAC;
	width: auto;
}

.product-info .product-info-extra.social a:last-child {
	border-right: 0;
	margin-right: 0;
	padding-right: 0;
}

.product-info .date {
	color: #9AA1AB;
	font-size: 0.9em;
}

.product-info.secondary .product-info-content {
	text-align: center;
}

.product-info.secondary .product-info-content h4 {
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	color: #FFF;
	position: relative;
	top: -25%;
	opacity: 0;
}

.product-info.secondary .product-info-content .date {
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	position: relative;
	top: 25%;
	display: block;
	opacity: 0;
}

.product-info.secondary .product-info-act {
	top: 30%;
}

.product-info.secondary .label {
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	clear: both;
	display: inline-block;
	margin-top: 15px;
	opacity: 0;
}

.product-info.secondary:hover .label {
	opacity: 1;
}

.product-info.secondary:hover .product-info-content h4 {
	top: 0;
	opacity: 1;
}

.product-info.secondary:hover .product-info-content .date {
	top: 0;
	opacity: 1;
}

/* Rating */
.shop .review-num {
	margin-left: 7px;
	float: left;
	font-size: 0.85em;
}

.shop .summary .price {
	color: #444;
	font-size: 2em;
	letter-spacing: -1px;
	line-height: 30px;
	margin-top: 10px;
	clear: both;
}

.shop .quantity {
	margin: 0 15px 25px 0;
	overflow: hidden;
	position: relative;
	width: 125px;
	height: 40px;
	float: left;
}

.shop .quantity .minus {
	background: transparent;
	border: 1px solid #F0F0F0;
	border-radius: 2px;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #5E5E5E;
	cursor: pointer;
	display: block;
	font-size: 12px;
	font-weight: bold;
	height: 40px;
	line-height: 13px;
	margin: 0;
	overflow: visible;
	outline: 0;
	padding: 0;
	position: absolute;
	text-align: center;
	text-decoration: none;
	vertical-align: text-top;
	width: 40px;
	border-radius: 0.25rem 0 0 0.25rem;
}

.shop .quantity .plus {
	background: transparent;
	border: 1px solid #F0F0F0;
	border-radius: 2px;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #5E5E5E;
	cursor: pointer;
	display: block;
	font-size: 12px;
	font-weight: bold;
	height: 40px;
	line-height: 13px;
	margin: 0;
	overflow: visible;
	outline: 0;
	padding: 0;
	position: absolute;
	text-align: center;
	text-decoration: none;
	vertical-align: text-top;
	width: 40px;
	border-radius: 0 0.25rem 0.25rem 0;
	right: 0;
	top: 0;
}

.shop .quantity .qty {
	border: 1px solid #F0F0F0;
	-webkit-box-shadow: none;
	box-shadow: none;
	float: left;
	height: 40px;
	padding: 0 39px;
	text-align: center;
	width: 125px;
	font-weight: bold;
	font-size: 1em;
	outline: 0;
	border-radius: .25rem;
}

.shop .quantity .qty::-webkit-inner-spin-button, .shop .quantity .qty::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.shop .quantity .qty::-ms-clear {
	display: none;
}

.shop .quantity.quantity-lg {
	height: 45px;
}

.shop .quantity.quantity-lg .minus {
	height: 45px;
	width: 45px;
}

.shop .quantity.quantity-lg .plus {
	height: 45px;
	width: 45px;
}

.shop .quantity.quantity-lg .qty {
	height: 45px;
}

.shop .product-meta {
	clear: both;
}

.shop .tabs-product {
	margin-top: 40px;
}

.shop ul.comments .comment-arrow {
	border-right-color: #F5F7F7;
}

.shop ul.comments .comment-block {
	background: #F5F7F7;
}

.shop table.cart {
	width: 100%;
	margin: 0;
	text-align: left;
}

.shop table.cart img {
	height: auto;
	width: 55px;
}

.shop table.cart th {
	padding: 8px 10px;
}

.shop table.cart td {
	border-top: 1px solid rgba(0, 0, 0, 0.06);
	padding: 8px 10px;
}

.shop table.cart td.actions {
	padding: 20px 0;
}

.shop table.cart .remove [class*="fa-"] {
	font-size: 1em;
}

.shop table.cart .coupon {
	float: left;
}

.shop table.cart .product-remove {
	width: 1%;
}

.shop table.cart .product-thumbnail {
	width: 8%;
}

.shop table.cart .product-name {
	width: 60%;
}

.shop table.cart .quantity {
	top: 10px;
	position: relative;
}

.shop .actions-continue {
	float: right;
}

.shop .cart-totals {
	width: 100%;
}

.shop .cart-totals th, .shop .cart-totals td {
	border-top: 1px solid rgba(0, 0, 0, 0.06);
	padding: 8px 10px;
}

.shop .cart-totals tr.total .amount {
	font-size: 2em;
	display: block;
	padding-top: 10px;
}

.shop ul.simple-post-list li {
	border: 0;
	padding-bottom: 0;
}

/* Product Thumb Info */
.product-thumb-info {
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	padding: 0;
	position: relative;
	border: 0;
	border-bottom: 3px solid #E3E4E8;
	background: #F7F9FC;
	display: block;
	overflow: hidden;
	position: relative;
	text-decoration: none;
	max-width: 100%;
	text-align: center;
	border: 1px solid #DDD;
	text-decoration: none;
}

.product-thumb-info:hover {
	background-color: #FFF;
	text-decoration: none;
}

.product-thumb-info a {
	text-decoration: none;
	display: inline-block;
	width: 100%;
}

.product-thumb-info a:hover {
	text-decoration: none;
	display: inline-block;
	width: 100%;
}

.product-thumb-info img {
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}

.product-thumb-info h4 {
	color: #404751;
	letter-spacing: -1px;
	font-weight: 600;
	font-size: 1.4em;
	line-height: 1.1em;
	margin: 0;
	padding: 0;
}

.product-thumb-info + .product-thumb-info {
	margin-top: -5px;
}

.product-thumb-info .product-thumb-info-touch-hover {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: none;
	background-color: transparent;
}

.product-thumb-info.hover .product-thumb-info-touch-hover {
	display: block;
}

.product-thumb-info .product-thumb-info-image {
	display: block;
	position: relative;
}

.product-thumb-info .product-thumb-info-image:before {
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #FFF;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.product-thumb-info:hover .product-thumb-info-image:before {
	opacity: 0.4;
}

.product-thumb-info .product-thumb-info-act {
	position: absolute;
	top: 40%;
	left: 0;
	right: 0;
	text-align: center;
	color: #FFF;
	opacity: 0;
}

.product-thumb-info:hover .product-thumb-info-act {
	opacity: 1;
	top: 50%;
}

.product-thumb-info .product-thumb-info-act em {
	font-style: normal;
}

.product-thumb-info .product-thumb-info-content {
	padding: 15px;
	text-align: left;
	display: block;
}

.product-thumb-info .product-thumb-info-caption {
	padding: 0 15px 15px;
	text-align: left;
	display: block;
}

.product-thumb-info .product-thumb-info-caption p {
	margin: 0;
	padding: 0 0 10px;
}

.product-thumb-info .product-thumb-info-extra {
	clear: both;
	padding: 10px 0 0;
	margin: 10px 0 0;
	border-top: 1px solid #EBEBF4;
	display: block;
}

.product-thumb-info .product-thumb-info-extra.social a:last-child {
	border-right: 0;
	margin-right: 0;
	padding-right: 0;
}

/* Product Thumb Info - Full Width */
.full-width .product-thumb-info .product-thumb-info-image:before {
	border-radius: 0;
}

/* Responsive */
@media (max-width: 991px) {
	.shop .actions-continue {
		float: none;
	}

	.shop table.cart .product-thumbnail {
		width: 12%;
	}

	.shop table.cart .product-name {
		width: 35%;
	}

	#header nav.mega-menu .mega-menu-shop a {
		color: #FFF;
	}

	#header nav.mega-menu .mega-menu-shop a .fa-caret-down {
		display: none !important;
	}

	#header nav.mega-menu .mega-menu-shop > ul.dropdown-menu {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.shop table.cart .product-name {
		width: 65%;
	}

	.shop table.cart th.product-price, .shop table.cart td.product-price, .shop table.cart th.product-quantity, .shop table.cart td.product-quantity, .shop table.cart .product-thumbnail {
		display: none;
	}
}
